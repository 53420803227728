.modal-icon {
    font-size: 3.5em;
    margin-top: .5em;
    margin-bottom: .7em;
    transition: opacity .2s ease;
    opacity: 0.8;
}

.wcard {
    border: none;
}

.modal-icon:hover {
    opacity: 1;
}

.modal-list {
    margin-top: 5%;
    margin-bottom: 5%;
}

.fa-viruses {
    color: #beca5c;
}

.fa-hat-cowboy-side {
    color: #c2b092;
}

.fa-database {
    color: #a1cae2;
}