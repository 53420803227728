@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.mainNav {
  padding: 1.2em 0;
  background-color: #93329e;
}
  
.fa-syringe {
  color: #ffffff;
  opacity: 0.6;
  font-size: 1.34em;
  margin-top: 5px;
  display: inline;
  transition: .2s ease;
}

.fa-syringe:hover {
  opacity: 0.9;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  opacity: 0.7;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.navbar-light .navbar-nav .show > .nav-link {
  color: #fff
}

.navbar-light .navbar-nav .nav-link:hover {
  opacity: 0.85;
  color: #fff
} 

.nav-container {
  margin: 0 5%;
  padding: 0;
} 
