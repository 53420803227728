@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-primary {
  color: #fff;
  background-color: #93329e;
  border-color: #93329e; /*set the color you want here*/
  box-shadow: none !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary{
  color: #fff;
  background-color: #93329e;
  border-color: #93329e;
  opacity: 60%;
}

.home-container {
  background-color: #59057b;
  padding: 7% 10% 10% 7.5%;
  border-bottom: solid .8em #59057b;
}
 
.splash-header {
  margin: 0;
  color: #fff;
  font-size: 6em;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  font-weight: 500;
}
  
.splash-subtext {
  color: white;
  opacity: .5;
  text-align: left;
  font-weight: 400;
}
.modal-icon {
    font-size: 3.5em;
    margin-top: .5em;
    margin-bottom: .7em;
    transition: opacity .2s ease;
    opacity: 0.8;
}

.wcard {
    border: none;
}

.modal-icon:hover {
    opacity: 1;
}

.modal-list {
    margin-top: 5%;
    margin-bottom: 5%;
}

.fa-viruses {
    color: #beca5c;
}

.fa-hat-cowboy-side {
    color: #c2b092;
}

.fa-database {
    color: #a1cae2;
}
.description {
    padding-left: 20%;
    padding-right: 20%;
}
.card {
    align-items: center
}

.card-img-top {
    height: 200px;
}
.postman-card {
    padding-top: 15px;
}



.mainNav {
  padding: 1.2em 0;
  background-color: #93329e;
}
  
.fa-syringe {
  color: #ffffff;
  opacity: 0.6;
  font-size: 1.34em;
  margin-top: 5px;
  display: inline;
  transition: .2s ease;
}

.fa-syringe:hover {
  opacity: 0.9;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  opacity: 0.7;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.navbar-light .navbar-nav .show > .nav-link {
  color: #fff
}

.navbar-light .navbar-nav .nav-link:hover {
  opacity: 0.85;
  color: #fff
} 

.nav-container {
  margin: 0 5%;
  padding: 0;
} 

