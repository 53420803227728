.home-container {
  background-color: #59057b;
  padding: 7% 10% 10% 7.5%;
  border-bottom: solid .8em #59057b;
}
 
.splash-header {
  margin: 0;
  color: #fff;
  font-size: 6em;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  font-weight: 500;
}
  
.splash-subtext {
  color: white;
  opacity: .5;
  text-align: left;
  font-weight: 400;
}